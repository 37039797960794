.containerHome {
  max-width: 1500px;
  // height: 100vh;
  margin: auto;
}

.containerHeaderHome {
  height: auto;
  background-color: #002b5e;
  // margin: auto;
  display: flex;
  justify-content: space-around;

  .imgHome {
    // max-width: 75%;
    height: 192px;
    object-fit: cover;
    // border: 2px solid red;
    display: flex;
    justify-content: center;
    margin-right: 20px;
  }
  .titleHome {
    // border: 2px solid green;
    color: #ff914d;
    font-size: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
  }
}
@media screen and (max-width: 510px) {
  .containerHeaderHome {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    .nav {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
