.generalContainerEditUser {
  max-width: 1500px;
  margin: auto;
  height: 100vh;
  .containerEditUser {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: 100vh;

    .containerHeaderHome {
      margin: 0;
      width: 100%;
    }
    .containerNavigation {
      width: 100%;
    }
    .row {
      width: 100%;
      // display: flex;
      // align-items: center;
      // margin: auto;
      margin-bottom: 20px;
      .col-md-6 {
        width: 80%;
        margin: auto;

        .card {
          border: 1px solid #ff914d;
          box-shadow: 1px 1px 5px #002b5e;
          width: 80%;
          margin: auto;
          .titleUser {
            color: #002b5e;
            font-weight: 700;
            text-align: center;
            margin-top: 20px;
          }
          hr {
            color: #002b5e;
          }
        }
        form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          label {
            color: #002b5e;
          }
          input {
            border: 1px solid #ff914d;
            text-align: center;
          }
          #foods {
            border: 1px solid #ff914d;
            text-align: center;
          }
          .btnEditUser {
            background-color: #002b5e;
            border: none;
            color: #ff914d;
            margin-top: 30px;
          }
        }
      }
    }
    .containerFooter {
      width: 100%;
      margin: 0;
      margin-top: auto;
    }
  }
}
@media screen and (max-width: 768px) {
  .generalContainerEditMeal {
    .containerEditMeal {
      .row {
        .col-md-6 {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .generalContainerEditUser {
    .containerEditUser {
      .row {
        margin-bottom: 20px;
        .col-md-6 {
          width: 100%;
        }
      }
    }
  }
}
