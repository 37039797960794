.containerHeaderAdmin {
  background-color: #002b5e;
  // width: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;

  .imgAdmin {
    max-width: 150px;
    height: 150px;
    display: flex;
    object-fit: cover;
  }
  .titleHeaderAdmin {
    color: #ff914d;
    font-size: 30px;
    display: flex;
    // justify-content: space-evenly;
    align-items: center;
    margin-left: 30px;
  }
}
@media screen and (max-width: 344px) {
  .containerHeaderAdmin {
    width: 100%;
    margin: auto;

    .nav {
      justify-content: center;
    }
  }
}
