.containerSportAdmin {
  width: 75%;
  // margin: auto;

  .btnAddAdmin {
    background-color: #002b5e;
    width: 50px;
    height: 50px;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    a {
      text-decoration: none;
      color: #fff;
      font-size: 30px;

      .btnAddSportAdmin {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .addSportAdmin {
          width: 100%;
          display: flex;
        }
      }
    }
  }
  .row {
    // border: 2px solid green;
    width: 100%;
    margin: 5px auto;
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    .cardSportAdmin {
      // border: 2px solid green;
      width: 100%;
      height: auto;
      display: flex;
      // flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      .card {
        border: 2px solid #ff914d;
        width: 100%;
        height: auto;
        box-shadow: 1px 1px 5px #002b5e;

        .card-body {
          // width: 50%;
          // margin: auto;
          // text-align: center;

          .titleCardAdmin {
            color: #002b5e;
            font-size: 25px;
            font-weight: 800;
            margin-bottom: 20px;
          }
          strong {
            color: #002b5e;
          }
          .editIconAdmin {
            color: #ff914d;
            margin-right: 20px;
          }
          .deleteIconAdmin {
            cursor: pointer;
            color: #002b5e;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 665px) {
  .containerSportAdmin {
    width: 100%;
  }
}
