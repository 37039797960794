.containerUserSideBar {
  display: flex;
  flex-direction: row;

  .rowUserAdmin {
    // border: 2px solid green;
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    .titleUserAdmin {
      width: 100%;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #002b5e;
    }
    .cardUserAdmin {
      // border: 2px solid green;
      width: 90%;
      height: auto;
      display: flex;
      // flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin: auto;
      .card {
        border: 2px solid #ff914d;
        width: 100%;
        height: auto;
        box-shadow: 1px 1px 5px #002b5e;

        .card-body {
          // width: 50%;
          // margin: auto;
          // text-align: center;

          .titleCardAdmin {
            color: #002b5e;
            font-size: 25px;
            font-weight: 800;
            margin-bottom: 20px;
          }
          p {
            color: #002b5e;
          }

          .deleteIconAdmin {
            cursor: pointer;
            color: #002b5e;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 665px) {
  .containerUserSideBar {
    width: 100%;
    flex-direction: column;
  }
}
