.generalEditFoodAdmin {
  .containerEditFoodAdmin {
    // border: 2px solid red;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .containerSideBar {
      height: 76vh;

      .nav {
        justify-content: none;
        height: 90vh;
      }
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      margin: auto;
      .col-md-6 {
        width: 100%;

        .card-body {
          border: 1px solid #ff914d;
          box-shadow: 1px 1px 5px #002b5e;
          width: 100%;
          h4 {
            color: #002b5e;
          }

          hr {
            color: #002b5e;
          }
        }
      }
      label {
        color: #002b5e;
      }
      input {
        border: 1px solid #ff914d;
        text-align: center;
      }
    }
    .btnEditFoodAdmin {
      background-color: #002b5e;
      border: none;
      color: #ff914d;
      margin-top: 30px;
    }
  }
  .containerFooterHome {
    height: 155px;
  }
}
@media screen and (max-width: 665px) {
  .generalEditFoodAdmin {
    .containerAdminAll {
      margin: 0;
      min-height: 100vh;
      display: flex;
      flex-direction: column;

      .containerEditFoodAdmin {
        width: 100%;
        flex-direction: column;
        margin-bottom: 20px;

        .containerSideBar {
          height: auto;

          .nav {
            height: auto;
          }
        }
      }
    }
    .containerFooter {
      margin: 0;
      margin-top: auto;
    }
  }
}
@media screen and (max-width: 768px) {
  .containerEditFoodAdmin {
    .row {
      width: 100%;
    }
  }
}
