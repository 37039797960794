.row {
  width: 80%;
}
.col {
  margin-bottom: 5px;
}
.form-label {
  font-weight: bold;
}

.containerRegister {
  max-width: 800px;
  height: 100vh;
  margin: auto;

  ////////////////////Titre
  .titleRegister {
    font-size: 2em;
    color: #002b5e;
    text-align: center;
    margin-top: 30px;
  }
  /////////////////Formulaire email mot de passe
  .formRegister {
    width: 100%;
    background-color: #d9d9d9;
    padding: 20px 0;
    margin-bottom: 20px;
    border-radius: 10px;

    #formBasicEmail {
      margin-bottom: 5px;
      width: 80%;
      margin: 20px auto 10px auto;
    }

    #formPassword {
      margin-bottom: 5px;
      width: 80%;
      margin: 20px auto 10px auto;
    }

    .input-group {
      width: 80%;
      margin: auto;
    }
    //////////////////////// Genre Homme Femme
    .sexeHF {
      width: 50%;
      margin: 10px auto;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      #homme {
        border: 1px solid #ff914d;
        margin-right: 10px;
      }
      #femme {
        border: 1px solid #ff914d;
        margin-right: 10px;
      }
    }
    ////////////////////////////checkbox général

    .form-check {
      width: 40%;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    /////////////////////////////// Age
    .ageRegister {
      width: 20%;
      margin: 20px auto 5px auto;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    ///////////////////////// Bordure input général
    .form-control {
      border: 1px solid #ff914d;
      width: auto;
      font-size: 15px;
      margin: auto;
    }
    /////////////////////////////// Taille Poids
    .sizeRegister {
      margin: 10px auto 5px auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .form-control {
        margin-bottom: 5px;
      }
    }
    //////////////////////////// Objectif

    .objectiveRegister {
      width: 100%;
      margin: 5px auto 20px auto;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .lossRegister {
        width: 50%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .form-check {
          width: 100%;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;

          #perteDePoids {
            border: 1px solid #ff914d;
            margin-right: 10px;
          }
        }
      }
      .stabilityRegister {
        width: 50%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        .form-check {
          width: 100%;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;

          #stabiliteDuPoids {
            border: 1px solid #ff914d;
            margin-right: 10px;
          }
        }
      }
    }
    ////////////////////////////////////// Activité
    .activityRegister {
      width: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;

      .noActiveregister {
        width: 50%;

        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        #pasActif {
          border: 1px solid #ff914d;
          margin-right: 10px;
        }
      }
      .noVeryActiveRegister {
        width: 50%;

        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        #peuActif {
          border: 1px solid #ff914d;
          margin-right: 10px;
        }
      }
      .activeRegister {
        width: 50%;

        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      #actif {
        border: 1px solid #ff914d;
        margin-right: 10px;
      }
    }
    //////////////////// Pseudo
    .pseudoregister {
      margin: 20px 0;
    }
    .createRegister {
      background-color: #002b5e;
      color: #ff914d;
      font-weight: bold;
      width: 95%;
    }

    .btnLoginRegister {
      text-decoration: none;
      color: #ff914d;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      margin: 20px auto 0 auto;
    }
  }
}
