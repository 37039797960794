.containerMentionL {
  max-width: 1500px;
  margin: auto;

  h1 {
    text-align: center;
    color: #002b5e;
    margin-bottom: 30px;
  }
  h2 {
    padding: 0 10px;
    color: #002b5e;
  }
  p {
    padding: 0 30px;
  }
  ul {
    li {
      margin-left: 50px;
    }
  }
}
