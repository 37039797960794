.containerSideBar {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .navBurger {
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    color: inherit;
    display: none;
  }
}
@media screen and (max-width: 665px) {
  .containerSideBar {
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    height: auto;
    padding-bottom: 20px;
    right: 0;
    bottom: -100vw;
    padding: 2rem;

    .navBurger {
      display: block;

      &:hover {
        cursor: pointer;
      }
    }
    .burgerBar {
      display: block;
      width: 40px;
      height: 3px;
      position: relative;
      border-radius: 3px;
      background-color: white;
      bottom: 20px;

      &::before {
        display: block;
        width: 40px;
        height: 3px;
        left: 0;
        border-radius: 3px;
        background-color: white;
        content: "";
        position: absolute;
        transform: translateY(-12px);
      }

      &::after {
        display: block;
        width: 40px;
        height: 3px;
        left: 0;
        position: absolute;
        border-radius: 3px;
        background-color: white;
        content: "";
        transform: translateY(12px);
      }
    }
  }
}
