.containerHeaderAuth {
  max-width: 1000px;
  margin-top: 5px;
  border-radius: 10px;
  background-color: #002b5e;

  .containerTitle {
    width: 100%;
    height: 350px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    a {
      width: 50%;

      .imgAdmin {
        max-width: 100%;
        height: auto;
      }
    }

    h1 {
      color: #ff914d;
      font-size: 3em;
    }
  }

  .textHeaderAuth {
    color: #ff914d;
    width: 100%;
    padding: 10px;
    text-align: center;

    span {
      font-size: 1.5em;
      font-weight: bold;
      display: block;
      line-height: 1.5;
      text-align: center;
      white-space: normal;
    }
  }

  /* Media query pour les écrans plus petits */
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 15px;
    border-radius: 0px;
    margin-top: 0;
    .containerTitle {
      flex-direction: column; /* Assurer l'alignement horizontal */
      justify-content: space-between; /* Espace entre le logo et le titre */
      align-items: center; /* Centrer verticalement les éléments */
      height: auto; /* Ajuster la hauteur pour s'adapter au contenu */

      a {
        width: auto; /* Laisser le logo occuper l'espace nécessaire */
        margin: auto;
        display: flex;
        justify-content: center;
        .imgAdmin {
          max-width: 40%; /* Réduire la taille du logo sur mobile */
        }
      }

      h1 {
        font-size: 1.8em; /* Ajuster la taille du titre sur mobile */
        width: auto;
        margin-left: 10px; /* Ajouter un petit espacement entre le logo et le titre */
        text-align: right; /* Aligner le titre à droite */
      }
    }

    .textHeaderAuth {
      span {
        font-size: 1.2em; /* Réduire la taille du texte sur mobile */
        line-height: 1.3;
      }
    }
  }

  @media (max-width: 480px) {
    .containerTitle {
      h1 {
        font-size: 1.6em; /* Encore plus petit pour les très petits écrans */
      }

      a {
        .imgAdmin {
          max-width: 35%; /* Réduire encore plus le logo */
          margin: auto;
        }
      }
    }

    .textHeaderAuth {
      span {
        font-size: 1em; /* Ajustement pour les très petits écrans */
      }
    }
  }
}
