.containerFooter {
  max-width: 1500px;
  margin-top: auto;

  .containerFooterHome {
    height: 150px;
    background-color: #002b5e;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    a {
      text-decoration: none;
    }
    p {
      color: #ffffff;
    }
  }
}
@media screen and (max-width: 460px) {
  .containerFooterHome {
    flex-direction: column;
    padding-top: 10px;
  }
}
