.containerInput {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  .inputDateCalendar {
    text-align: center;
    font-size: 1em;
    position: relative;
    background-color: #d9d9d9;
    border: none;
    color: #002b5e;
    font-weight: bold;
    width: 80%;
  }
  .iconCalendar {
    position: absolute;
    right: 30px;
    color: #002b5e;
  }
}

.containerCalendar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 40px 0;

  span {
    color: #002b5e;
    font-size: 1.2em;
    font-weight: bold;
    padding-right: 10px;
  }
  .inputCalendar {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 340px) {
  .containerCalendar {
    display: block;

    .labelSpan {
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
