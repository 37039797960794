.containerLogin {
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: auto;
  .titleLogin {
    font-size: 2em;
    color: #002b5e;
    text-align: center;
    margin-top: 30px;
  }
  .formLogin {
    width: 100%;
    height: auto;
    background-color: #d9d9d9;
    border-radius: 10px;
    padding: 20px 5px;

    #formBasicEmail {
      border: 1px solid #002b5e;
    }
    #formBasicPassword {
      border: 1px solid #002b5e;
    }

    .btnLogin {
      background-color: #ff914d;
      border: none;
      color: #002b5e;
      font-weight: bold;
    }
    .createAccountLogin {
      text-decoration: none;
      color: #002b5e;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      margin: 20px auto 0 auto;
    }
  }
}
