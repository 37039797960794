.generalContainerEditMeal {
  max-width: 1500px;
  margin: auto;
  height: 100vh;
  .containerEditMeal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .containerHeaderHome {
      margin: 0;
      width: 100%;
    }
    .containerNavigation {
      width: 100%;
    }
    .row {
      width: 100%;
      display: flex;
      align-items: center;
      margin: auto;
      .card {
        border: 1px solid #ff914d;
        box-shadow: 1px 1px 5px #002b5e;
        .titleEditMeal {
          color: #002b5e;
          font-weight: 700;
        }
        hr {
          color: #002b5e;
        }
      }
      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        label {
          color: #002b5e;
        }
        input {
          border: 1px solid #ff914d;
          text-align: center;
        }
        #foods {
          border: 1px solid #ff914d;
          text-align: center;
        }
        p {
          color: #002b5e;
          font-weight: 700;
        }
        .btnEditMeal {
          background-color: #002b5e;
          border: none;
          color: #ff914d;
          // margin-top: 30px;
        }
      }
    }
    .containerFooter {
      width: 100%;
      margin: 0;
      margin-top: auto;
    }
  }
}
@media screen and (max-width: 500px) {
  .generalContainerEditMeal {
    .containerEditMeal {
      .row {
        margin-bottom: 20px;
      }
    }
  }
}
