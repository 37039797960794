.containerNavigation {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 40px 0;
  flex-wrap: wrap;
  a {
    text-decoration: none;
    color: #002b5e;
    font-size: 1.3em;
    font-weight: bold;
    display: flex;
  }
  .navBurger {
    width: 40px;
    height: 40px;
    background: transparent;
    border: none;
    color: inherit;
    display: none;
  }
}
@media screen and (max-width: 660px) {
  .containerNavigation {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // position: fixed;

    right: 0;
    bottom: -100vw;
    // height: 100vh;
    padding: 2rem;
    background-color: #fff;
    .navBurger {
      display: block;

      &:hover {
        cursor: pointer;
      }
    }
    .burgerBar {
      display: block;
      width: 40px;
      height: 3px;
      position: relative;
      border-radius: 3px;
      background-color: #002b5e;
      bottom: 20px;

      &::before {
        display: block;
        width: 40px;
        height: 3px;
        left: 0;
        border-radius: 3px;
        background-color: #002b5e;
        content: "";
        position: absolute;
        transform: translateY(-12px);
      }

      &::after {
        display: block;
        width: 40px;
        height: 3px;
        left: 0;
        position: absolute;
        border-radius: 3px;
        background-color: #002b5e;
        content: "";
        transform: translateY(12px);
      }
    }
  }
}
