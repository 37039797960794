.containerAdminAll {
  max-width: 1200px;
  margin: auto;
  // display: flex;
  background-color: white;

  .containerSideBarAdmin {
    width: 25%;
    height: auto;
    // border: 2px solid red;
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
    // align-items: center;
    flex-wrap: wrap;
    background-color: #002b5e;

    .nav {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: 100vh;
      a {
        color: #ff914d;
        font-size: 20px;
      }
    }
  }
}
@media screen and (max-width: 665px) {
  .containerAdminAll {
    .containerSideBarAdmin {
      width: 100%;
      .nav {
        justify-content: center;
        height: auto;
      }
    }
  }
}
.card-body {
  width: 100%;
  margin: auto;
  text-align: center;
}
