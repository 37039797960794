.titleMeals {
  font-size: 1.8em;
  text-align: center;
  font-weight: bold;
  color: #002b5e;
  background-color: #d9d9d9;
  margin-bottom: 30px;
  padding: 7px 0;
}

.meals {
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: auto;
  // border: 2px solid red;

  .breakfast {
    width: 100%;
    height: auto;
    margin: 20px auto;

    text-align: center;

    a {
      text-decoration: none;
      color: inherit;

      h3 {
        color: #002b5e;
        font-weight: 500;
      }
    }
    .mealBreakfast {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      p {
        margin-right: 20px;
        color: #002b5e;
        font-weight: 600;
        margin-top: 20px;
      }
      .editIconBreakfast {
        color: #ff6201;
        margin-right: 10px;
      }
      .deleteIconBreakfast {
        color: #002b5e;
        margin-left: 10px;
      }
    }
  }
  .lunch {
    width: 100%;
    height: auto;
    margin: 20px auto;
    // border: 2px solid green;
    text-align: center;

    a {
      text-decoration: none;
      color: inherit;

      h3 {
        color: #002b5e;
        font-weight: 500;
      }
    }
    .mealLunch {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      p {
        margin-right: 20px;
        color: #002b5e;
        font-weight: 600;
        margin-top: 20px;
      }
      .editIconLunch {
        color: #ff6201;
        margin-right: 10px;
      }
      .deleteIconLunch {
        color: #002b5e;
        margin-left: 10px;
      }
    }
  }
  .dinner {
    width: 100%;
    height: auto;
    margin: 20px auto;
    // border: 2px solid green;
    text-align: center;

    a {
      text-decoration: none;
      color: inherit;

      h3 {
        color: #002b5e;
        font-weight: 500;
      }
    }
    .mealDinner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 20px;

      p {
        margin-right: 20px;
        color: #002b5e;
        font-weight: 600;
        padding-top: 20px;
      }
      .editIconDinner {
        color: #ff6201;
        margin-right: 10px;
      }
      .deleteIconDinner {
        color: #002b5e;
        margin-left: 10px;
      }
    }
  }
  .snack {
    width: 100%;
    height: auto;
    margin: 20px auto;
    // border: 2px solid green;
    text-align: center;

    a {
      text-decoration: none;
      color: inherit;

      h3 {
        color: #002b5e;
        font-weight: 500;
      }
    }
    .mealSnack {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      p {
        margin-right: 20px;
        color: #002b5e;
        font-weight: 600;
        margin-top: 20px;
      }
      .editIconSnack {
        color: #ff6201;
        margin-right: 10px;
      }
      .deleteIconSnack {
        color: #002b5e;
        margin-left: 10px;
      }
    }
  }
}
@media screen and (max-width: 380px) {
  .mealBreakfast {
    flex-direction: column;
    p {
      margin: 0;
      margin-top: 0;
    }
    .editIconBreakfast {
      margin-top: 20px;
    }
    .deleteIconBreakfast {
      margin-right: 20px;
      margin-top: 20px;
    }
  }

  .mealLunch {
    flex-direction: column;

    p {
      margin: 0;
      margin-top: 0;
    }
    .editIconLunch {
      margin-top: 20px;
    }
    .deleteIconLunch {
      margin-right: 20px;
      margin-top: 20px;
    }
  }

  .mealSnack {
    flex-direction: column;

    p {
      margin: 0;
      margin-top: 0;
    }
    .editIconSnack {
      margin-top: 20px;
    }
    .deleteIconSnack {
      margin-right: 20px;
      margin-top: 20px;
    }
  }

  .mealDinner {
    flex-direction: column;

    p {
      margin: 0;
      margin-top: 0;
    }
    .editIconDinner {
      margin-top: 20px;
    }
    .deleteIconDinner {
      margin-right: 20px;
      margin-top: 20px;
    }
  }
}
