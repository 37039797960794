.generalContainerEditUserActivity {
  max-width: 1500px;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .containerEditUserActivity {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    flex: 1;

    .containerHeaderHome {
      margin: 0;
      width: 100%;
    }
    .containerNavigation {
      width: 100%;
    }
    .row {
      width: 100%;
      display: flex;
      align-items: center;
      margin: auto;
      .card {
        border: 1px solid #ff914d;
        box-shadow: 1px 1px 5px #002b5e;
        .titleEditUserActivity {
          color: #002b5e;
          font-weight: 700;
        }
        hr {
          color: #002b5e;
        }
      }
      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        label {
          color: #002b5e;
        }
        input {
          border: 1px solid #ff914d;
          text-align: center;
        }
        #useractivity {
          border: 1px solid #ff914d;
          text-align: center;
        }
        .btnEditUserActivity {
          background-color: #002b5e;
          border: none;
          color: #ff914d;
          margin-top: 30px;
        }
        p {
          color: #002b5e;
          font-weight: 700;
        }
      }
    }
    .containerFooter {
      width: 100%;
      margin: 0;
      margin-top: auto;
    }
  }
}
@media screen and (max-width: 500px) {
  .generalContainerEditUserActivity {
    .containerEditUserActivity {
      .row {
        margin-bottom: 20px;
      }
    }
  }
}
