.caloriesHome {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  padding: 30px 0;

  .totalCaloriesConsumed {
    width: auto;
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    padding: 5px 10px;
    background-color: #002b5e;
    color: #ff914d;
  }
  .caloriesPerDay {
    width: auto;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d9d9d9;
    border-radius: 50%;
    font-size: 1.2em;
    font-weight: bold;
    color: #002b5e;
    margin-top: 30px;
    padding: 0 50px;
  }
  .totalCaloriesRemaining {
    width: auto;
    background-color: #ff914d;
    color: #002b5e;
    font-size: 1em;
    font-weight: bold;
    padding: 5px 25px;
    margin-top: 30px;
  }
}
