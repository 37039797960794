.titleActivity {
  font-size: 1.8em;
  text-align: center;
  font-weight: bold;
  color: #002b5e;
  background-color: #d9d9d9;
  margin-bottom: 30px;
  padding: 7px 0;
}

.userActivity {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .sport {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    label {
      margin-right: 20px;
      font-weight: 500;
      color: #002b5e;
    }
    #sportSelect {
      border: 1px solid #ff6201;

      color: #002b5e;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .duration {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    label {
      margin-right: 20px;
      font-weight: 500;
      color: #002b5e;
    }
    #durationInput {
      border: 1px solid #ff6201;
      color: #002b5e;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .btnValidate {
    background-color: #002b5e;
    color: #ff914d;
    font-weight: bold;
    width: 150px;
    border-radius: 10px;
    border: none;
  }
}
.totalUserActivity {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;

  .sportActivity {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: row;
    justify-content: space-evenly;
    color: #002b5e;
    font-weight: 600;

    .editIconActivity {
      color: #ff6201;
    }
    .deleteIconActivity {
      color: #002b5e;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 5px;
    }
  }
  .totalCaloriesBurned {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;

    strong {
      color: #002b5e;
      margin-right: 20px;
    }
    span {
      color: #ff914d;
      font-weight: 600;
    }
  }
}
@media screen and (max-width: 340px) {
  .totalCaloriesBurned {
    p {
      display: flex;
      flex-direction: column;

      strong {
        text-align: center;
      }
      span {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 461px) {
  .sportActivity {
    flex-direction: column;
    text-align: center;

    .deleteIconActivity {
      text-align: center;
      width: 100%;
      margin-top: 10px;
    }
  }
}
